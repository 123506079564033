:root {
  --color-black-rgb: 20, 20, 20;
  --color-yellow-rgb: 255, 253, 113;

  --color-black: rgb(var(--color-black-rgb));
  --color-yellow: rgb(var(--color-yellow-rgb));
}

html {
  display: contents;
  height: 100%;
  background: var(--color-yellow);
  background: #6865ff;
}

body, #app {
  display: contents;
}

body {
  font-family: "DM Serif Text",serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  color: var(--color-yellow);
  background-repeat: repeat-x repeat-y;
}

#animation {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#moment {
  z-index: -100;
  position: relative;
}

#moment video {
  object-fit: contain;
  position: fixed;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  object-position: 50% 50%;
  z-index: -1;
}

#moment::before {
  backdrop-filter: blur(3px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  content: '';
  background: rgba(0,0,0,.2);
}

/* #moment::after {
  top: 0;
  left: 0; */
  /* position: fixed; */
  /* font-size: 4em;
  color: white; */
  /* filter: blur(1px); */
  /* text-shadow: 2px 2px 0px rgba(255,0,0,.5), -2px -2px 0px rgba(0,255,255,.5);
  font-family: t26-carbon, monospace;
  text-transform: uppercase;
  content: "PLAY";
  z-index:  2;
} */



img#moment {
  opacity: 0;
}

#moment.loaded {
  opacity: 1;
  transition: all ease .7s;
}

main {
  height: 100%;
  width: 100vw;
  display: grid;
  grid-auto-rows: auto 1fr;
}

section#content {
  overflow: auto;
  display: grid;
  grid-template-rows: 1fr [definition] auto;
}

header {
  display: grid;
  place-items: center;
  gap: 1rem;
}

nav {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  gap: 1rem;
}

nav a, nav button {
  line-height: 1em;
  font-size: 1rem;
  padding: 1em;
  border: none;
  color: var(--color-yellow);
  transition: background-color 100ms, outline 50ms, opacity ease 200ms;
  outline: none;
  text-decoration: none;
  backdrop-filter: blur(5px);
  background: rgba(var(--color-black-rgb), .7);
  outline: 0px solid var(--color-yellow);
}

nav svg {
  fill: var(--color-yellow);
  height: 1em;
}

nav a.active, nav button.active {
  background:  var(--color-yellow);
  color: black;
}

nav a:hover, nav button:hover:not([disabled]) {
  background: var(--color-black);
  color: var(--color-yellow);
  outline: 3px solid var(--color-yellow);
}

nav button:disabled {
  opacity: .1;
}

#brand {
  font-size: 3em;
  color: var(--color-yellow);
  text-decoration: none;
  line-height: 1em;
}

::selection {
  background: #18d0f1;
}

#definition {
  color: var(--color-yellow);
  font-size: 1.2em;
  font-family: monospace;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: end;
  grid-row-start: definition;
  opacity: .8;
}

.section-padding {
  padding: 1rem;
  transition: 100ms padding;
}

.section-backdrop {
  background: rgba(0,0,0,.5);
  backdrop-filter: blur(10px);
}

.deck {
  font-size: 4em;
  font-style: italic;
  text-align: center;
  padding: 1rem;
}

.nav-vertical {
  grid-auto-flow: row;
  justify-items: start;
  align-self: end;
}

@media only screen and (min-width: 600px) {
  header {
      place-items: unset;
      grid-auto-flow: column;
      justify-content: space-between;
  }

  .section-padding {
      padding: 2rem;
  }
}

@media only screen and (max-height: 480px) {
  .section-padding { 
    padding: 1rem;
  }

  main {
    overflow: auto;
  }

  section#content {
    overflow: unset;
  }
}